
































































































import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'ModuleInstruct',
  props: {
    instruction: {
      required: true,
      type: Object,
      default: Object
    }
  },
  setup(props, ctx) {
    const instructionValue: any = computed({
      get: () => props.instruction,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const boilerInstructions = ref(instructionValue.value.boilerInstructions);
    const goal = ref(instructionValue.value.goal);
    watch(
      () => instructionValue.value,
      value => {
        // use toRaw here to get a readable console.log result
        instructionValue.value = value;
        boilerInstructions.value = value.boilerInstructions;
        goal.value = value.goal;
      },
      { deep: true }
    );
    // function populate() {
    //   boilerInstructions.value.push('');
    // }
    return { instructionValue, boilerInstructions, tutorialDialog: false, goal };
  }
};
